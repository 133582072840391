import * as Webapi from "@ihr-radioedit/inferno-webapi";
import { MusicPageType } from "../../lib/catalog";
import { MusicTrack } from "../../page-blocks/music/MusicTrack.component";
import "./TrackList.style.scss";

export interface TrackListProps {
  data: (Webapi.CurrentlyPlayingTrackDetailFragment | Webapi.TopTrackDetailFragment)[];
  pageType: MusicPageType;
  currentTrackId?: number;
}

const TrackList = ({ data: tracks, pageType, currentTrackId = 0 }: TrackListProps) => {
  if (!tracks.length) {
    return null;
  }
  return (
    <ul className="component-track-list">
      {tracks.map((track: Webapi.CurrentlyPlayingTrackDetailFragment | Webapi.TopTrackDetailFragment, i: number) => (
        <li className="track-list-item" key={i}>
          <MusicTrack track={track} type={pageType} current={track.trackId === currentTrackId} />
        </li>
      ))}
    </ul>
  );
};

export default TrackList;

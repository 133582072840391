import classnames from "classnames";
import { inject } from "mobx-react";
import { useTranslation } from "react-i18next";

import { LiveIcon } from "../../components/icons/LiveIcon.component";
import { ResponsiveImage } from "../../components/ResponsiveImage.component";
import type { CurrentlyPlayingTrackDetailFragment, TopTrackDetailFragment } from "@ihr-radioedit/inferno-webapi";
import { imageURI, MusicPageType } from "../../lib/catalog";
import { CatalogKindTypes, generateCatalogUrl } from "@ihr-radioedit/inferno-core";
import { isCurrentlyPlayingTrackDetailFragment } from "../../lib/guards";
import type { SrcSet, Store } from "@inferno/renderer-shared-core";
import { DateTimeDisplay, MagicLink } from "../../ui";
import "./MusicTrack.style.scss";
import { RESPONSIVE_IMAGE } from "../../ui/constants";
import { getLiveStationUrl } from "@inferno/renderer-shared-core";

interface MusicTrackProps {
  track: TopTrackDetailFragment | CurrentlyPlayingTrackDetailFragment;
  type: MusicPageType;
  store?: Store;
  current?: boolean;
}

export const MusicTrack = inject("store")(({ track, store, type, current = false }: MusicTrackProps) => {
  if (!store || !store.env.IMAGE_HOST) {
    return null;
  }
  const { t } = useTranslation();
  const showTime = type === MusicPageType.RECENT;
  const showCounter = type === MusicPageType.TOP;
  const trackClass = classnames("component-track-display", {
    "with-counter": showCounter,
    [`type-${type}`]: type,
  });

  const currentTrack = isCurrentlyPlayingTrackDetailFragment(track) ? track : track.track;

  const songUrl = generateCatalogUrl(
    { national_site_url: store.env.NATIONAL_SITE_URL, node_env: store.env.NODE_ENV },
    {
      track: currentTrack,
      kind: CatalogKindTypes.song,
    },
  );
  const artistUrl = generateCatalogUrl(
    { national_site_url: store.env.NATIONAL_SITE_URL, node_env: store.env.NODE_ENV },
    {
      track: currentTrack,
      kind: CatalogKindTypes.artist,
    },
  );
  const albumUrl = generateCatalogUrl(
    { national_site_url: store.env.NATIONAL_SITE_URL, node_env: store.env.NODE_ENV },
    {
      track: currentTrack,
      kind: CatalogKindTypes.album,
    },
  );
  const liveUrl = getLiveStationUrl(store.site, store.env);
  const trackImg = imageURI(store.env.IMAGE_HOST, "track", currentTrack.trackId);
  const imgSize = RESPONSIVE_IMAGE.musicTrackImageWidth;
  const defaultSrc = `${trackImg}?ops=gravity("center"),ratio(1,1),fit(${imgSize},${imgSize}),quality(80)`;
  const srcSet: SrcSet[] = trackImg
    ? [
        {
          url: defaultSrc,
          descriptor: "1x",
        },
        {
          url: `${trackImg}?ops=gravity("center"),ratio(1,1),fit(${imgSize * 2},${imgSize * 2}),quality(80)`,
          descriptor: "2x",
        },
      ]
    : [];

  const sizes = [
    {
      media: "(max-width: 767px)",
      size: `${imgSize}px`,
    },
    {
      media: "(min-width: 768px)",
      size: `${imgSize * 2}px`,
    },
    {
      media: "auto",
      size: "",
    },
  ];

  return (
    <figure className={trackClass}>
      <section className="thumb">
        <MagicLink to={songUrl} target="_blank">
          <ResponsiveImage
            src={defaultSrc}
            srcset={srcSet}
            sizes={sizes}
            alt={`${currentTrack.title} by ${currentTrack.artist?.artistName}`}
            initialWidth={imgSize}
            initialHeight={imgSize}
          />
        </MagicLink>
      </section>
      <figcaption>
        <MagicLink className="track-title" to={songUrl} target="_blank">
          <span>{currentTrack.title}</span>
        </MagicLink>
        <MagicLink className="track-artist" to={artistUrl} target="_blank">
          <span>{currentTrack.artist?.artistName}</span>
        </MagicLink>
        <MagicLink className="track-album" to={albumUrl} target="_blank">
          <span>{currentTrack.albumName}</span>
        </MagicLink>
        {current && liveUrl ? (
          <MagicLink to={liveUrl} label={t("listen_live")} className="live-indicator" target="_blank">
            <LiveIcon />
          </MagicLink>
        ) : null}
        {!(current && liveUrl) && showTime && isCurrentlyPlayingTrackDetailFragment(track) && track?.startTime ? (
          <DateTimeDisplay
            className="track-time"
            timestamp={track.startTime}
            format="h:mm a"
            zone_id={store.site.index.timeZone || undefined}
          />
        ) : null}
      </figcaption>
    </figure>
  );
});
